<template>
    <div class="user">
      <button @click="CloseUser" class="menu-button-logout" ><i class="fas fa-times"></i></button>

      <div class="logo-web">
        <img src="@/assets/ll.png" alt="" width="100%" height="100%" />
      </div>
  

    <v-card width="100%" max-width="80%" class="py-1" rounded="xxl" color="#fff"  >      <!-- สีฟอร์ม -->
        <div class="center-frame">
            <!-- เบอร์โทร เครดิต โบนัส -->
            <div class="info-item">
            <p class="label">เบอร์โทร</p>
            <p class="label1">เครดิต</p>
            <p class="label1">โบนัส</p>
            </div>
    
            <!-- ยอดเครดิต -->
            <div class="info-item">
            <p class="value">{{ phoneNumber }}</p>
            <p class="value1">฿ 0.00</p>
            <p class="value1">฿ 0.00</p>
            </div>
        </div>
    </v-card>
      <!-- ปุ่มเมนู -->
    <v-container class="menu-buttons">
      <v-row justify="center" flex-wrap="wrap">
        <v-col cols="12" md="6">
          <v-btn
              class="menu-button-1"
              x-large
              dark 
              width="100%"
              ><v-icon left> mdi-bank </v-icon>
          
              ฝากเงิน / ถอนเงิน
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <v-btn
              class="menu-button-1"
              x-large
              dark
              width="100%"
              ><v-icon left> mdi-gift </v-icon>
          
              โปรโมชั่น
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <v-btn
              class="menu-button-1"
              x-large
              dark 
              width="100%"
              @click="openModal"
              ><v-icon left> mdi-code-tags </v-icon>
          
              กรอกโค้ด
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
              class="menu-button-1"
              x-large
              dark
              width="100%"
              href="https://lin.ee/x0fyxHq"
              target="_blank"
              ><v-icon left> mdi-gift </v-icon>
          
              ติดต่อแอดมิน
          </v-btn>
        </v-col>
      </v-row>
    </v-container>



    <!-- Modal กรอกโค้ด -->
    <div v-if="showModal" class="code-modal">
      <div class="modal-content">

        <p>ใส่โค้ดรับเครดิตฟรี</p>
        <v-form class="form-custom" ref="form" @submit.prevent="receiveCode">
          <v-text-field
            solo
            flat
            outlined
            dense
            ref="form.code"
            v-model="code"
            required
            :rules="[
              () => !!code || 'โปรดกรอกโค้ดที่ได้รับมา',
            ]"
            maxlength="20"
            label="ใส่โค้ดที่นี่"
            prepend-inner-icon="mdi-code-tags"
          ></v-text-field>

          <v-btn
            x-large
            type="submit"
            color="red"
            dark
            width="100%"
            class="mb-4"
          >
            <v-icon left>fas fa-gift</v-icon>
            ตรวจสอบ
          </v-btn>
        </v-form>
        <button @click="CloseModalCode" class="menu-button-logout-code">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  
    <!-- ปุ่มทางเข้าเล่น -->
    <v-btn
          class="play-button"
          color="red"
          x-large
          type="submit"
          dark width="100%"
          @click="goProviders"
          ><v-icon left> mdi-gamepad-variant </v-icon>
          ทางเข้าเล่น
    </v-btn>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showModal: false,
      };
    },
    computed: {
    phoneNumber() {
      return this.$store.state.phoneNumber;
    },
  },
    methods: {
      openModal() {
        this.showModal = true;
      },
      CloseModalCode() {
        this.showModal = false;
        this.code ="";
      },
      receiveCode() {
      if (this.$refs.form.validate()) {
        this.$router.push('/Game'); // เปลี่ยนเส้นทางไปยังหน้า .....vue หลังจากที่กดยืนยันโค้ด
      }
      },
      goProviders() {
        this.$router.push('/Game'); // เปลี่ยนเส้นทางไปหน้า .....vue หลังจากกดปุ่มทางเข้าเล่น
      },
      CloseUser() {
        this.$router.push('/Login'); // เปลี่ยนเส้นทางไปหน้า Login เมื่อกดปุ่ม X ปิด
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>

// effect สั่น
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}
.play-button {
  animation: shake 2s infinite; /* ปรับตามความเหมาะสม */
}

// ปุ่มกดปิด X
.menu-button-logout
      {
        color: orange; /* เปลี่ยนสีไอคอน*/
        font-size: 40px;
        position: absolute;
        top: 20px;
        right: 20px;
      }

  .user {
    height: 100%;
    width: 100vw;
    
    // สีพื้นหลัง (ไล่สี)
    /* background: radial-gradient(at 100% 100%, #5f5f5f, rgba(0, 0, 0, 0) 40vw), radial-gradient(at 0% 0%, #be643a, rgba(0, 0, 0, 0) 40vw) ; */
    background-image: url(../assets/bg.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    background-size: cover, contain;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  
    // โลโก้
    .logo-web {
      width: 100px; // ขนาดโลโก้
      height: 100px; // ขนาดโลโก้
      margin-bottom: 50px;
      transform: scale(2); /* เพิ่มขนาด ... เท่า */
      img {
        object-fit: cover;
      }
    }
  
    // รายละเอียด
    .center-frame {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px;
      position: relative;
      box-shadow: -3px 3px 1px rgba(255, 153, 0, 0.5); // ปรับสีเงากล่องรายละเอียด
      
  
      .info-item {
        flex: 1;
  
        .label {
          color: orange;
          text-align: left;
        }

        .label1 {
            color: black;
            text-align: left;

        }
  
        .value {
          color: orange;
          text-align: right;
        }
        
        .value1 {
            color: black;
            text-align: right;
        }
      }
    }
  
    // ปุ่มเมนูต่างๆ
    .menu-buttons {
      margin-top: 10px;
      margin-bottom: 10px;
  
      .menu-button-1{
        background-color: orange; // เปลี่ยนสีปุ่ม
        color: #000;
        margin: 0 5px;
        cursor: pointer;

      }
      .menu-button-2{
        background-color: orange;
        color: #000;
        margin: 0 5px;
        cursor: pointer;

      }
    }
  
    // หน้าต่างกรอกโค้ด
    .code-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
  
      .menu-button-logout-code{
        color: #fff; /* เปลี่ยนสีไอคอนให้สีดำ */
        font-size: 15px;
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-color: red;
        border-radius: 50%;
        margin-left: 0px;

      }
      .modal-content {
        background: #fff;
        padding: 20px;
        text-align: center;
  
        p {
          font-size: 18px;
          margin-bottom: 10px;
        }
  
        .receive-button {
          background-color: #ff0000; // เปลี่ยนสีปุ่มกรอกโค้ด
          color: #fff;
          padding: 10px;
          border: none;
          cursor: pointer;
        }
      }
    }
  
    .play-button {
      background-color: #ff0000; // เปลี่ยนสีปุ่มทางเข้าเล่น
      color: #fff;
      padding: 15px 30px;
      max-width: 300px;
      border: none;
      cursor: pointer;
    }
  }
  </style>
  